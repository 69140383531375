<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-mypage" >

    <section class="content-wrap st-center">

      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box">

          <div class="mypage-wrap">
            <div class="aside-box">
              <div class="people-info-box">
                <div class="img-box">
                  <img class="member-list-box" v-if="userDetail.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
                  <img class="member-list-box" v-else :src="`/attachment/user/photo/${userDetail.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
                </div>
                <div class="name-box">
                  <div class="name"><em>{{ userDetail.name }}</em><span>님</span></div>
                  <div class="cate">{{ userDetailDepartment }} / {{ memberClub(userDetail.clubGroup) }}</div>
                </div>
                <div class="btn-box">
                  <router-link to="/my/message">쪽지함</router-link>
                  <router-link to="/my/inquiry">1:1 문의</router-link>
                </div>
              </div>
              <div class="menu-box">
                <ul>
                  <li><router-link to="/my/myInfo">회원정보</router-link></li>
                  <li><router-link to="/my/inquiry">활동 내역</router-link>
                    <ul>
                      <li><router-link to="/my/inquiry">1:1 문의</router-link></li>
                      <li><a href="javascript:;" @click="onClickMenu">내 게시글</a></li>
                      <li><router-link to="/my/message">쪽지함</router-link></li>
                    </ul>
                  </li>
                  <li><router-link to="/my/meetList">모임 내역</router-link></li>
                  <li class="on"><router-link to="/my/videoConference">화상 회의</router-link>
                    <ul>
                      <li><router-link to="/my/videoConference">전체 회의</router-link></li>
                      <li class="on"><router-link to="/my/myConference">내가 개설한 회의</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="main-body-box">
              <div class="my-tit-box">회원 초대</div>
              <div class="board-search-wrap st4">
                <div class="search-box">
                    <span class="label-box mr10">조건검색</span>
                    <select name="" id="" class="input-st1 st-selectbox wd150 mr10 dg-ml-5" style="height: 40px;line-height: 38px;" v-model="param.searchDepartment">
                      <option value="">분과</option>
                      <option v-for="department in departmentItems" :key="department.groupId" :value="department.groupId">{{ department.groupName }}</option>
                    </select>
                    <select name="" class="input-st1 st-selectbox wd150 mr10 dg-ml-5" style="height: 40px;line-height: 38px;" v-model="param.searchClub">
                      <option value="">동호회</option>
                      <option v-for="club in clubItems" :key="club.groupId" :value="club.groupId">{{ club.groupName }}</option>
                    </select>
                    <input type="text" v-model="param.searchKeyword" placeholder="검색어를 입력해주세요." class="input-st1 wd260 mr10 dg-ml-5" style="height: 40px;line-height: 38px;" @keydown="onKeydown">
                    <a href="javascript:;" @click="onClickBeforeSearch" style="height: 40px;line-height: 38px;" class="btn-s dg-ml-5">검색</a>
                </div>
              </div>
              <div class="board-btn-box st-between">
                <div class="txt-box">선택한 회원 수 <em>{{selectCheckBox.length}}</em> 명</div>
                <a href="javascript:;" class="btn-mod" @click="onClickInvite">초대하기</a>
              </div>
              <div class="tb-01 st2 st-over">
                <table>
                  <colgroup><col style="width:70px"><col style="width:70px"><col style="width:100px"><col style="width:130px"><col style="width:*"><col style="width:180px"><col style="width:130px"><col style="width:130px"></colgroup>
                  <thead>
                  <tr>
                    <th>
                      <input type="checkbox" class="chk-st1 st-none" id="chkall" v-model="checkAll" @change="onClickCheckAll"><label for="chkall">전체선택</label>
                    </th>
                    <th>번호</th>
                    <th>사진</th>
                    <th>이름</th>
                    <th>휴대폰번호</th>
                    <th>분과</th>
                    <th>동호회</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(member, idx) in memberList" :key="member.userId">
                    <td class="al-center">
                      <input type="checkbox" v-model="currentListSelectBox" :value="member" class="chk-st1 st-none" :id="'chk010'+member.userId" @change="onClickCheckBox(member)"><label :for="'chk010'+member.userId">선택</label>
                    </td>
                    <td class="al-center">{{ idx + 1 + ((pagination.currentPage-1) * pagination.pageSize) }}</td>
                    <td class="al-center">
                      <img class="member-list-box" v-if="member.photoFile==null" src="../../../assets/front/images/icon/member.jpg"/>
                      <img class="member-list-box" v-else :src="`/attachment/user/photo/${member.photoFile}`" @error="(e) => e.target.src=require('@/assets/front/images/icon/member.jpg')"/>
                    </td>
                    <td class="al-center">
                      <a href="javascript:;" class="btnPeopleDtlOver" @click="onClickUser(member.userId)">{{member.name}}</a>
                    </td>
                    <td class="al-center">{{ phoneFormatter(member.mobileNo) }}</td>
                    <td class="al-center">{{ memberSubdivision(member.departmentGroup) }}</td>
                    <td class="al-center">{{ memberClub(member.clubGroup )}}</td>

                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- class : pagination (S) -->
              <DgPagination v-model="pagination" @pageSelected="pageSelected"/>
              <!-- class : pagination (E) -->

              <CommonPopup v-if="memberPopupOpen" :tap="popupTab" :userId="userId" @click:bgDimClose="popupMemberList" ></CommonPopup>

            </div>
          </div>

        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import userApi from "@/api/UserApi";
import DgPagination from '@/views/component/DgPagination';
import groupApi from "@/api/GroupApi";
import CommonPopup from "@/views/include/common_popoup";
import hypermeetingApi from "@/api/HyperMeetingApi";

export default {
  name: "video_index",
  components:{
    DgPagination,
    CommonPopup
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    isOpen(e){
      if(!e){
        this.memberPopupOpen=false;
      }
    },
  },
  data(){
    return {
      userId: this.$store.state.user.userid,
      userDetail: {},
      userDetailDepartment: '',

      memberPopupOpen: false,
      popupTab: 'detail',

      pagination:{
        currentPage: 1,
        pageSize: 8,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      selectCheckBox:[],
      currentListSelectBox:[],
      checkAll:false,

      param: {
        searchColumn: 'all',
        searchKeyword: null,
        searchDepartment:'',
        searchClub:'',
        page: 1
      },

      departmentItems:[],
      clubItems:[],
      memberList:[],

      roomId:this.$route.params.roomId
    }
  },
  created(){
    if(this.userId == '') {
      alert('로그인 후 이용하실 수 있습니다.')
      this.$router.push(`/login`)
    }else{
      this.getUserDetail();
      this.getReady();
    }
  },
  methods: {
    getReady(){
      groupApi.flatList(1).then(data => {
        this.departmentItems = data;
      });
      groupApi.flatList(2).then(data => {
        this.clubItems = data;
      });

      //this.param.searchColumn = this.$route.query.searchColumn!=''?this.$route.query.searchColumn:'all';
      if(this.$route.query.searchColumn!=''){
        this.param.searchColumn = this.$route.query.searchColumn;
      }else{
        delete this.param.searchColumn;
      }
      this.param.searchKeyword = this.$route.query.searchKeyword;
      this.param.searchDepartment = '';
      this.param.searchClub = '';
      this.onClickSearch();
    },
    phoneFormatter(val){
      if (val != null) {
        if (val.length == 11) {
          return val.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (val.length == 8) {
          return val.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
          if (val.indexOf("02") == 0) {
            return val.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          } else {
            return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        }
      }
    },
    getUserDetail(){
      userApi.get(this.userId).then((data) =>{
        this.userDetail = data
        this.userDetailDepartment = data.departmentGroup[0].groupName
      })
    },
    memberClub(val){
      if(val != null) {
        let clubLength = val.length
        let userClubs = clubLength > 0 ? val[0].groupName : ''
        if (clubLength > 1) {
          userClubs += " 외 " + (clubLength - 1)
        }
        return userClubs
      }else{
        return '';
      }
    },
    onClickBeforeSearch(){
      this.pagination={
        currentPage: 1,
        pageSize:8,
        pageGroupSize: 10,
        totalPages:0,
        totalElements:0
      },

      delete this.param.searchColumn;
      this.onClickSearch();
    },
    onClickSearch(){
      this.currentListSelectBox=[];

      const params = {...this.param};

      params.fromDate = null;
      params.toDate = null;
      params.searchState = 'STATE_NORMAL'
      params.size = this.pagination.pageSize
      params.page = this.pagination.currentPage
      userApi.list(params).then(data => {
        this.memberList = data.content;
        this.pagination.totalElements=data.totalElements
        let totalPages = Math.floor(data.totalElements / this.pagination.pageSize) + ((data.totalElements % this.pagination.pageSize) > 0 ? 1 : 0) ;
        this.pagination.totalPages = totalPages;

        this.pushToCurrentCheckBox()

      })
    },
    pageSelected(pagination){
      this.checkAll=false;
      this.pagination.currentPage = pagination.currentPage;
      this.onClickSearch();
    },
    pushToCurrentCheckBox(){
      this.memberList.forEach(data=>{
        for(let i=0; i<this.selectCheckBox.length; i++){
          if (this.selectCheckBox[i].userId==data.userId) {
            this.currentListSelectBox.push(data);
          }
        }
      })

      let checkSize = this.pagination.totalElements < this.pagination.pageSize? this.pagination.totalElements : this.pagination.pageSize;
      if(this.currentListSelectBox.length == checkSize && checkSize>0){
        this.checkAll = true
      }else{
        this.checkAll = false
      }
    },
    menuParse(m) {
      if(!m.activated) {
        return null;
      }

      if(m.menuType === 'TYPE_BOARD') {
        m.url = `/board/${m.boardId}`;
      }
      return {
        menu: m,
        id : m.menuId,
        protected : m.protected,
        title : m.menuName,
        icon : m.menuIcon,
        href : m.url??null,
        child : m?.childMenus?.length??0 > 0 ? m?.childMenus?.map(this.menuParse).filter(c => c !== null) : null
      }
    },
    onClickCheckAll(){
      if(this.checkAll){
        this.memberList.forEach(data=>{
          let findIdx = this.selectCheckBox.findIndex(obj=>obj.userId==data.userId)
          if(findIdx == -1){
            this.selectCheckBox.push(data);
            this.currentListSelectBox.push(data);
          }
        })
      }else{
        this.memberList.forEach(data=>{
          let findIdx = this.selectCheckBox.findIndex(obj=>obj.userId==data.userId)
          // console.log('findIdx:'+findIdx)
          if(findIdx !== -1){
            this.selectCheckBox.splice(findIdx, 1)
            this.currentListSelectBox=[];
          }
        })
      }
    },
    onClickCheckBox(val){
      let checkSize = this.pagination.totalElements < this.pagination.pageSize? this.pagination.totalElements : this.pagination.pageSize;
      if(this.currentListSelectBox.length == checkSize){
        this.checkAll=true
      }else{
        this.checkAll=false
      }

      let findIdx = this.selectCheckBox.findIndex(obj=>obj.userId==val.userId)
      if(findIdx == -1){
        this.selectCheckBox.push(val);
      }else{
        this.selectCheckBox.splice(findIdx, 1)
      }
    },
    onKeydown(e){
      if(e.keyCode===13){
        this.onClickBeforeSearch();
      }
    },
    onClickMenu(){
      this.memberList= [],
      this.selectCheckBox=[],
      this.checkAll=false,

      this.searchColumn='boardName',
      this.searchKeyword=''

      this.onClickSearch();
    },
    onClickUser(userId){
      this.userId = userId
      this.popupMemberList(true)
    },
    popupMemberList(e) {
      this.memberPopupOpen = e
      this.$emit('click:bgDim', this.memberPopupOpen)
    },
    memberSubdivision(val){
      if(val!=null) {
        let subdivisionLength = val.length
        let userSubdivision = subdivisionLength > 0 ? val[0].groupName : ''
        return userSubdivision
      }
    },
    onClickInvite(){
      if(this.selectCheckBox.length==0){
        return alert('1명 이상 초대해주세요.');
      }

      let payload = {
        roomId : this.roomId
      }

      payload.sendUserList = this.selectCheckBox.map(s => s.userId);

      if(this.roomId && this.selectCheckBox) {
        let memberList = '';
        for(let i=0; i<this.selectCheckBox.length; i++){
          memberList += this.selectCheckBox[i].name+'님';
          if(i < this.selectCheckBox.length-1){
            memberList += ", ";
          }
        }

        if(confirm(memberList + '께 초대장을 보내시겠습니까?')){
          hypermeetingApi.invite(this.roomId, payload).then(result => {
            console.log(result);
            this.$router.push('/my/myConference');
          })
        }


      }
    }
  },
}
</script>

<style scoped>

</style>